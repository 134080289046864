import { CardverifyComponent } from './../../component/cardverify/cardverify.component';
import { CardupdateComponent } from './../../component/cardupdate/cardupdate.component';
import { SafePipe } from './../../pipes/safe.pipe';
import { NgxStripeModule } from 'ngx-stripe';
import { CardpaymentComponent } from './../../component/cardpayment/cardpayment.component';
import { BtnLoadingComponent } from './../../component/extra/btn-loading/btn-loading.component';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmptysatesComponent } from './../../component/extra/emptysates/emptysates.component';
import { LoadingComponent } from './../../component/extra/loading/loading.component';
import { NgPipesModule } from 'ngx-pipes';
import { ReactiveFormsModule } from '@angular/forms';
import { LoadingBarModule, LoadingBarService } from '@ngx-loading-bar/core';
import { FooterComponent } from 'src/app/component/footer/footer.component';
import { HeaderComponent } from '../header/header.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    LoadingComponent,
    EmptysatesComponent,
    BtnLoadingComponent,
    CardpaymentComponent,
    CardupdateComponent,
    CardverifyComponent,
    SafePipe,
    FooterComponent,
    HeaderComponent,
  ],
  imports: [
    CommonModule,
    NgPipesModule,
    NgxStripeModule.forRoot('pk_test_LMORONHfCBS7UTbaMNBu7goe'),
    ReactiveFormsModule,
    LoadingBarModule,
    RouterModule,
  ],
  exports: [
    LoadingComponent,
    EmptysatesComponent,
    BtnLoadingComponent,
    CardpaymentComponent,
    CardupdateComponent,
    CardverifyComponent,
    SafePipe,
    FooterComponent,
    HeaderComponent,
  ],
  providers: [LoadingBarService],
})
export class SharedmoduleModule {}
