import { AuthService } from './../../service/auth/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { StripeService, StripeCardComponent } from 'ngx-stripe';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { Router } from '@angular/router';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';

declare var $: any;
@Component({
  selector: 'app-cardupdate',
  templateUrl: './cardupdate.component.html',
  styleUrls: ['./cardupdate.component.scss'],
})
export class CardupdateComponent implements OnInit {
  public isBtnDisabled = true;

  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    },
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  stripeTest: FormGroup;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private stripeService: StripeService,
    private authservice: AuthService
  ) {}

  ngOnInit(): void {

    this.stripeTest = this.fb.group({
      name: ['', [Validators.required]],
    });
  }
  getToken() {
    $('#cardChange').modal('hide');
    $('#exampleModal').modal('show');
    this.isBtnDisabled = true

    this.authservice.getClientSecrete().subscribe(
      (data: any) => {
        console.log(data);

        this.createToken(data.client_secret);
      },
      (err) => {
        $('#exampleModal').modal('hide')
        this.isBtnDisabled = false

      }
    );
  }
  createToken(key): void {
    this.isBtnDisabled = true

    const name = this.stripeTest.get('name').value;
    let clientSecret = key;
    this.stripeService
      .confirmCardPayment(clientSecret, {
        payment_method: {
          card: this.card.element,
          billing_details: {
            name: 'sarath dixit',
          },
        },
        setup_future_usage: 'off_session',
      })
      .subscribe(
        (data: any) => {
          $('#exampleModal').modal('hide');
          console.log(data);
          console.log(data.status);
          window.location.reload();
          // this.router.navigate(['/dashboard/myproject']);
        },
        (err) =>  {
          $('#exampleModal').modal('hide')
          this.isBtnDisabled = false

        }
      );
    // this.stripeService
    //   .createToken(this.card.element, { name })
    //   .subscribe((result) => {
    //     if (result.token) {
    //       // Use the token
    //       console.log(result.token.id);
    //     } else if (result.error) {
    //       // Error creating the token
    //       console.log(result.error.message);
    //     }
    //   });
  }

  onChange(ev: StripeCardElementChangeEvent) {
    console.log(ev);

    if(ev.complete == true) {
     this.isBtnDisabled = false

    } else {
     this.isBtnDisabled = true
    }
    
}
}
