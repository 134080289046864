import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-emptysates',
  templateUrl: './emptysates.component.html',
  styleUrls: ['./emptysates.component.scss'],
})
export class EmptysatesComponent implements OnInit {
  @Input() message: string;

  constructor() {}

  ngOnInit(): void {}
}
