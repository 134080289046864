import { SocketoneService, SocketthreeService, SockettwoService } from './service/socketconnection.service';
import { SharedmoduleModule } from './module/sharedmodule/sharedmodule.module';
import { AuthService } from './service/auth/auth.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { HeaderComponent } from './component/header/header.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

import { AuthInterceptor } from './service/Interceptor/authInterceptor/auth.interceptor';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import {ConnectionServiceModule} from 'ng-connection-service';  


@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, AppRoutingModule, HttpClientModule, GooglePlaceModule, SocketIoModule, SharedmoduleModule,ConnectionServiceModule],
  providers: [
    AuthService,
    CookieService,
    SocketoneService,
    SockettwoService,
    SocketthreeService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
