import { AuthService } from './../../service/auth/auth.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { StripeService, StripeCardComponent } from 'ngx-stripe';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
declare var $: any;
@Component({
  selector: 'app-cardverify',
  templateUrl: './cardverify.component.html',
  styleUrls: ['./cardverify.component.scss'],
})
export class CardverifyComponent implements OnInit {
  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    },
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  stripeTest: FormGroup;

  constructor(
    private loadingBar: LoadingBarService,
    private router: Router,
    private fb: FormBuilder,
    private stripeService: StripeService,
    private authservice: AuthService
  ) {}

  ngOnInit(): void {
    this.stripeTest = this.fb.group({
      name: ['', [Validators.required]],
    });
  }
  getToken() {
    $('#cardChange').modal('hide');
    // $('#exampleModal').modal('show');
    this.loadingBar.start(0);
    this.authservice.getClientSecrete().subscribe(
      (data: any) => {
        console.log(data);

        this.createToken(data.client_secret);
      },
      (err) => {
        // $('#exampleModal').modal('hide')
        this.loadingBar.stop();
      }
    );
  }
  createToken(key): void {
    const name = this.stripeTest.get('name').value;
    let clientSecret = key;
    this.stripeService
      .confirmCardPayment(clientSecret, {
        payment_method: {
          card: this.card.element,
          billing_details: {
            name: 'sarath dixit',
          },
        },
        setup_future_usage: 'off_session',
      })
      .subscribe(
        (data: any) => {
          // $('#exampleModal').modal('hide');
          this.loadingBar.start(0);
          console.log(data);
          console.log(data.status);

          // this.router.navigate(['/dashboard/myproject']);
        },
        (err) => {
          // $('#exampleModal').modal('hide')
          this.loadingBar.stop();
        }
      );
    // this.stripeService
    //   .createToken(this.card.element, { name })
    //   .subscribe((result) => {
    //     if (result.token) {
    //       // Use the token
    //       console.log(result.token.id);
    //     } else if (result.error) {
    //       // Error creating the token
    //       console.log(result.error.message);
    //     }
    //   });
  }
}
