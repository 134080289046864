<div class="modal hide" data-keyboard="false" data-backdrop="static" tabindex="-1" role="dialog" id="exampleModal">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <span class="loadText">Please wait...</span>
      </div>
    </div>
  </div>
</div>
