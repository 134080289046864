import { AuthService } from './../../service/auth/auth.service';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { StripeService, StripeCardComponent } from 'ngx-stripe';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { Router } from '@angular/router';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';

declare var $: any;
@Component({
  selector: 'app-cardpayment',
  templateUrl: './cardpayment.component.html',
  styleUrls: ['./cardpayment.component.scss'],
})
export class CardpaymentComponent implements OnInit {
  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  @Input() button;
  @Input() bidID;
  @Input() projectID;
  public isBtnDisabled = true;

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0',
        },
      },
    },
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  stripeTest: FormGroup;

  constructor(
    private loadingBar: LoadingBarService,
    private router: Router,
    private fb: FormBuilder,
    private stripeService: StripeService,
    private authservice: AuthService
  ) {}

  ngOnInit(): void {
    this.stripeTest = this.fb.group({
      name: ['', [Validators.required]],
    });
    console.log(this.button);
    console.log(this.bidID);
    console.log(this.stripeTest);
    
  }
  getToken() {
    this.isBtnDisabled = true
    $('#AddCardModal').modal('hide');
    $('#cardChange').modal('hide');
    this.loadingBar.start(0);


    if (this.button == 'Verify') {
      console.log('verify true');

      this.authservice.getClientSecrete().subscribe(
        (data: any) => {

          this.loadingBar.stop();
          console.log(data);
          this.createToken(data.client_secret);
        },
        (err) => {
          this.loadingBar.stop();
          this.isBtnDisabled = false;

        }
      );
      return;
    } else {
      this.isBtnDisabled = true;

      console.log(this.button);
      this.authservice.projectClientSecrete(this.bidID).subscribe(
        (data: any) => {
          this.isBtnDisabled = false;

          console.log(data);
          this.createToken(data.client_secret);
        },
        (err) => {
          this.loadingBar.stop();
          this.isBtnDisabled = false;

        }
      );
    }
  }
  createToken(key): void {
    this.isBtnDisabled = true
    const name = this.stripeTest.get('name').value;
    let clientSecret = key;
    this.stripeService
      .confirmCardPayment(clientSecret, {
        payment_method: {
          card: this.card.element,
          billing_details: {
            name: 'sarath dixit',
          },
        },
        setup_future_usage: 'off_session',
      })
      .subscribe(
        (data: any) => {

          this.loadingBar.stop();

          console.log(data);

          console.log('response running');
          if (data.paymentIntent?.status == 'succeeded' && this.projectID == 'undefined') {
            this.router.navigate(['/dashboard/myproject/']);
          } else if (data.paymentIntent?.status == 'succeeded' && this.projectID !== 'undefined') {

            $('#docSuccessModal').modal('show');
          } else {
            $('#docFailModal').modal('show');

          }
        },
        (err) => {
          this.isBtnDisabled = false
          console.log('err running');

          console.log(err);
          this.loadingBar.stop();

        }
      );

  }

  ongoingpage() {
    this.router.navigate(['/dashboard/myproject/' + this.projectID]);
  }

  onChange(ev: StripeCardElementChangeEvent) {
       console.log(ev);

       if(ev.complete == true) {
        this.isBtnDisabled = false

       } else {
        this.isBtnDisabled = true
       }
       
  }
}
