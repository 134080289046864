<ngx-loading-bar [includeSpinner]="false" [color]="'#0066FF'"></ngx-loading-bar>

<form novalidate (ngSubmit)="getToken()" [formGroup]="stripeTest" >
  <!-- <input type="text" formControlName="name" placeholder="Jane Doe"> -->
  <ngx-stripe-card [options]="cardOptions" (change)="onChange($event)" [elementsOptions]="elementsOptions"></ngx-stripe-card>
  <button type="submit" [disabled]="isBtnDisabled">
    {{ button }}
  </button>
</form>

<!-- <app-btn-loading></app-btn-loading> -->

<!-- document upload success modal start -->

<!-- Modal -->
<div
  class="modal fade"
  id="docSuccessModal"
  data-backdrop="static"
  data-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div class="text-center mt-5">
          <img src="../../../assets/other/successAnimation.gif" alt="" style="width: 124px" />
          <h3 class="mt-3">Your payment is successful</h3>
          <p class="mt-3">An automated payment receipt will be sent to your register email address</p>
        </div>
      </div>
      <div class="modal-footer" style="border: none">
        <button type="button" class="btn btn-secondary" (click)="ongoingpage()" data-dismiss="modal">
          Click to continue
        </button>
      </div>
    </div>
  </div>
</div>

<!-- end -->

<!-- document upload success modal start -->

<!-- Modal -->
<div
  class="modal fade"
  id="docFailModal"
  data-backdrop="static"
  data-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
        <div class="text-center mt-5">
          <img src="../../../assets/other/failureAnimation.gif" alt="" style="width: 124px" />
          <h3 class="mt-3">Your payment failed</h3>
          <p class="mt-3">Please try again</p>
        </div>
      </div>
      <div class="modal-footer" style="border: none">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<!-- end -->
