import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';

@Injectable()
export class SocketoneService extends Socket {
  constructor() {
    console.log('service one');
    super({ url: 'https://clientapi.thewalnutco.com', options: {} });
  }
}

@Injectable()
export class SockettwoService extends Socket {
  constructor() {
    super({ url: 'https://engineerapi.thewalnutco.com', options: {} });
  }
}

@Injectable()
export class SocketthreeService extends Socket {
  constructor() {
    super({ url: 'https://adminapi.thewalnutco.com', options: {} });
  }
}
