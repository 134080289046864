import { Router } from '@angular/router';

import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthService } from './../../auth/auth.service';
import { retry, catchError } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private injector: Injector, private cookie: CookieService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log(req.url);
    let authservice = this.injector.get(AuthService);

    if (authservice.getToken()) {
      req = req.clone({
        setHeaders: { Authorization: `Bearer ${authservice.getToken()}` },
      });
    }
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        let router = this.injector.get(Router);
        if (error.status < 200) {
          if (error.error) {
            alert(error.error);
          } else {
            alert(`Informational response Error`);
          }
        }
        if (error.status < 400) {
          if (error.error) {
            console.log(error);
            alert(error.error.error);
          } else {
            alert(`Client errors `);
          }
        }
        if (error.status < 600) {
          if (error.status == 401) {
            this.cookie.deleteAll('/')
            router.navigate(['/onboard/login']);
          }

          if (error.error) {
            console.log(error);
            alert(error.error.error);
            if (error.error.error == 'Client not found!') {
              router.navigate(['/onboard/signup']);
            }
          } else {
            alert(` Server errors`);
          }
        }
        return throwError(error);
      })
    );
  }
}
