import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../environments/environment.prod';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public API = environment.apiUrl;
  public showHeader = new BehaviorSubject<boolean>(false)


  public profileData = new BehaviorSubject<any>({});

  public avatarData = new BehaviorSubject<any>(false);
  public headerColor = new Subject<any>();
  public notificationID = new Subject<string>();
  public header = new Subject<boolean>();
  public checkBlock = new Subject<boolean>();

  constructor(private http: HttpClient, private cookieService: CookieService) {}

  signup(data) {
    return this.http.post(this.API + '/client', data);
  }

  login(data) {
    return this.http.post(this.API + '/client/login', data);
  }

  register(data) {
    console.log(this.cookieService.get('temp'));

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + this.cookieService.get('temp'),
      }),
    };
    return this.http.put(this.API + '/client', data, httpOptions);
  }

  logout() {
    return this.http.put(this.API + '/client/logout', {});
  }
  verifyemail(data) {
    return this.http.put(this.API + '/client/confirmEmail/' + data, {});
  }
  getToken() {
    return this.cookieService.get('token');
  }

  loggedIn() {
    return this.cookieService.check('token');
  }

  viewprofile() {
    return this.http.get(this.API + '/client');
  }
  viewfullprofile() {
    return this.http.get(this.API + '/client/profile');
  }
  updateprofile(data) {
    return this.http.patch(this.API + '/client', data);
  }

  forgetPassword(data) {
    return this.http.post(this.API + '/client/forgotPassword', data);
  }

  resetPassword(id, data) {
    return this.http.put(this.API + '/client/resetPassword/' + id, data);
  }

  resendEmail(data) {
    return this.http.put(this.API + '/client/confirmEmail', data);
  }

  isLoggedIn() {
    return !!this.cookieService.check('token');
  }
  isRegisterCheck() {
    return !!this.cookieService.check('register');
  }

  getClientSecrete() {
    return this.http.post(this.API + '/payments/first', {});
  }

  projectClientSecrete(id) {
    return this.http.post(this.API + '/payments/bid/' + id, {});
  }

  notificationClear() {
    return this.http.get(this.API + '/client/notification/clear');
  }
}
